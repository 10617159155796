import React from 'react';
import { connect } from 'react-redux';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  VRSSalesContent,
  NamedRedirect,
} from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { LoadingPage } from '../LoadingPage/LoadingPage';

import css from './VRSSalesPage.module.css';

// if not logged in redirect user to contracts page (signup for contracts)
const VRSSalesPage = () => {
  return (
    <StaticPage className={css.root} title="Contracts Info Page">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <VRSSalesContent />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => ({
  user: state.user.currentUser,
  userLoading: state.user.currentUserLoading,
});

export default connect(mapStateToProps, null)(VRSSalesPage);
